<template lang="pug">
  .w-countdown.widget
    img(src="@/assets/img/countdown-bg.jpg")
    v-animate
      .w-countdown__content(v-if="!done")
        .w-countdown__time
          .w-countdown__time-item
            .w-countdown__time-value {{ days | addzero }}
            .w-countdown__time-label days
          .w-countdown__time-item
            .w-countdown__time-value {{ hours | addzero }}
            .w-countdown__time-label hours
          .w-countdown__time-item
            .w-countdown__time-value {{ min | addzero }}
            .w-countdown__time-label minutes
          .w-countdown__time-item
            .w-countdown__time-value {{ seconds | addzero }}
            .w-countdown__time-label seconds
        .w-countdown__ps ... and we will be married!
      .w-countdown__content(v-else)
        .w-countdown__end-view
          icon-heart
          span Just married!
</template>

<script>
import IconHeart from '@/components/svg/IconHeart'
const DEADLINE = process.env.VUE_APP_MODE !== 'demo'
  ? new Date('2021-10-30T13:00')
  : new Date(Date.now() + (3600 * 1000 * 24 * 21.4))

export default {
  name: 'CountdownWidget',

  components: {
    IconHeart
  },

  data: () => ({
    interval: null,
    seconds: 0,
    min: 0,
    hours: 0,
    days: 0,
    inited: false,
    done: false
  }),

  filters: {
    addzero (value) {
      return value < 10
        ? `0${value}`
        : value
    }
  },

  mounted () {
    this.interval = setInterval(this.updateTimer, 1000)
  },

  destroyed () {
    clearInterval(this.interval)
  },

  methods: {
    updateTimer () {
      const now = new Date()
      const s = (DEADLINE - now) / 1000
      if (s > 1) {
        this.seconds = Math.floor(s % 60)
        this.min = Math.floor((s / 60) % 60)
        this.hours = Math.floor((s / 60 / 60) % 24)
        this.days = Math.floor(s / 60 / 60 / 24)
      } else {
        this.stopTimer()
      }
      this.inited = true
    },

    stopTimer () {
      clearInterval(this.interval)
      this.seconds = 0
      this.min = 0
      this.hours = 0
      this.days = 0
      this.done = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-countdown {
    z-index: 1;

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__end-view {
      color: $color-white;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100vw;

      span {
        font-size: 8rem;
        font-family: "Drama";
      }
    }

    &__time {
      display: flex;
      justify-content: space-around;
      padding-top: 5rem;
      margin-bottom: 1.8rem;
    }

    &__time-item {
      color: $color-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 9rem;
    }

    &__time-value {
      font-size: 5rem;
    }

    &__time-label {
      position: relative;
      font-size: 1.4rem;
      letter-spacing: 5%;
      margin-top: 3.8rem;

      &::before {
        content: "";
        position: absolute;
        height: 2.5rem;
        width: .1rem;
        top: -3.4rem;
        right: 50%;
        background-color: rgba($color-white, .6);
      }
    }

    &__ps {
      color: $color-white;
      text-align: center;
      font-size: 4rem;
      font-style: normal;
      font-weight: normal;
      font-family: "Drama";
    }

    &__icon {
      color: $color-white;
    }
  }
</style>
