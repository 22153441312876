<template lang="pug">
  .widget.timing-widget
    img.timing-widget__decor(src="@/assets/illustrations/flower-branch.svg")
    .title Таймінг дня
    .timing-widget__item(
      v-for="(item, index) in items"
      :key="index")
      .timing-widget__item-time {{ item.time }}
      .timing-widget__item-label {{ item.label }}
</template>

<script>
export default {
  name: 'TimingWidget',

  data: () => ({
    items: [
      { time: '12:30', label: 'Церемонія вінчання' },
      { time: '15:00', label: 'Святковий банкет' },
      { time: '18:00', label: 'Закінчення офіційної частини' },
      { time: '19:00', label: 'Продовження для молоді' },
      { time: '21:00', label: 'Нappy end' }
    ]
  })
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .timing-widget {
    padding: 12rem 0rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    .title {
      text-align: center;
      margin-bottom: 5rem;
    }

    &__decor {
      width: 3rem;
      margin: 0 auto;
      margin-bottom: 4rem;
    }

    &__content {
      padding: 4rem 0;
    }

    &__item {
      display: flex;
      align-items: center;
      width: 32.5rem;
      margin: 0 auto;

      &:not(:last-child) {
        margin-bottom: 2.8rem;
      }

      &-time {
        width: 8rem;
        text-align: center;
        padding: 1rem 1.4rem;
        font-size: 1.6rem;
        margin-right: 2.4rem;
        border: .1rem solid $color-primary;
      }

      &-label {
        font-size: 1.4rem;
      }
    }
  }
</style>
