<template lang="pug">
  .w-info.widget
    .w-info__content
      img.w-info__decor(src="@/assets/illustrations/flower-branch.svg")
      template(v-if="hasGuests")
        .small-title {{ title }}
        .w-info__subtitle.script-title(
          :class="classes") {{ guests }}
        p.w-info__subsubtitle З радістю запрошуємо вас на#[br] наше весілля, яке відбудеться:
      p.mb-3 Жовтень 2021
      img.w-info__calendar(src="@/assets/svg/calendar.svg")
      template(v-if="hasGuests")
        p P.S. Вперше у весільній сукні наречений#[br] побачить наречену саме на вінчанні.#[br] Радимо не пропустити цей момент :)
</template>

<script>
export default {
  name: 'InfoWidget',

  data: () => ({
    hasCyrilic: false
  }),

  computed: {
    invitation () {
      return this.$store.getters['invitation/invitation']
    },

    title () {
      if (this.invitation && this.invitation.texts.caption) {
        return this.invitation.texts.caption
      }
      return 'Дорогі'
    },

    hasGuests () {
      return this.invitation &&
        this.invitation.guests.length
    },

    guests () {
      if (this.invitation) {
        return this.invitation.guests
          .map(item => {
            const index = item.name.indexOf('(')
            if (index !== -1) {
              return item.name.substring(0, index - 1)
            }
            return item.name
          })
          .join(', ') + '!'
      }
      return '-'
    },

    you () {
      if (this.invitation && this.invitation.texts.you) {
        return this.invitation.texts.you
      }
      return 'вас'
    },

    classes () {
      return {
        'w-info__subtitle--fallback': this.hasCyrilic
      }
    }
  },

  watch: {
    guests (value) {
      this.hasCyrilic = /[а-яА-ЯЁё]/gi.test(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-info {
    padding-top: 8rem;
    padding-bottom: 10rem;

    &__decor {
      width: 3rem;
      display: inline-block;
      margin-bottom: 1.5rem;
    }

    &__content {
      position: relative;
      padding: 0 2rem;
      text-align: center;
      z-index: 1;
    }

    &__subtitle {
      display: inline-block;
      text-align: center;
      min-width: 27rem;
      margin-top: 1.5rem;
      padding-bottom: 2.5rem;
      margin-bottom: 3.2rem;
      border-bottom: 1px solid rgba($color-primary, .6);
    }

    &__subtitle--fallback {
      font-size: 2.8em;
      font-family: "Prata" !important;
    }

    &__subsubtitle {
      margin-bottom: 5.5rem;
    }

    &__calendar {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 4.5rem;
    }
  }
</style>
