<template lang="pug">
  .w-rsvp.widget
    h2.w-rsvp__title Чи зможете ви приєднатись#[br] до святкування разом з нами?
    .w-rsvp__block
      .w-rsvp__item(
        v-for="(item, index) in guests"
        :key="index")
        span.w-rsvp__item-label {{ item.name | name }}
        .w-rsvp__item-btns
          button.btn.btn--outline(
            :class="{ 'btn--active': item.rsvp.answer }"
            @click="onUpdate(item, true)")
            | Так
          button.btn.btn--outline(
            :class="{ 'btn--active': item.rsvp.answer === false }"
            @click="onUpdate(item, false)")
            | Ні
    template(v-if="showSecondBlock")
      h3.w-rsvp__title На яких частинах свята#[br] плануєте бути присутніми?
      .w-rsvp__block
        .w-rsvp__item(
          v-for="(item, index) in parts"
          :key="index")
          span.w-rsvp__item-label {{ item.name }}
          .w-rsvp__item-btns
            button.btn.btn--outline(
              :class="{ 'btn--active': compareTo(item, true) }"
              @click="onSecondUpdate(item, true)")
              | Так
            button.btn.btn--outline(
              :class="{ 'btn--active': compareTo(item, false) }"
              @click="onSecondUpdate(item, false)")
              | Ні
</template>

<script>
export default {
  name: 'RSVPWidget',

  data: () => ({
    parts: [
      { name: 'Вінчання', answer: null, key: 'ceremony' },
      { name: 'Банкет', answer: null, key: 'banquet' }
    ]
  }),

  filters: {
    name (name) {
      const index = name.indexOf('(')
      if (index !== -1) {
        return name.substring(0, index - 1)
      }
      return name
    }
  },

  computed: {
    invitation () {
      return this.$store.getters['invitation/invitation']
    },

    guests () {
      return this.invitation
        ? this.invitation.guests
        : []
    },

    showSecondBlock () {
      return this.guests
        .some(item => item.rsvp.answer === true)
    }
  },

  methods: {
    onUpdate (item, answer) {
      item.rsvp.answer = answer
      this.$store.dispatch('invitation/update', { guests: this.guests })
    },

    onSecondUpdate (item, answer) {
      for (const guest of this.guests) {
        guest.rsvp[item.key] = answer
      }
      this.$store.dispatch('invitation/update', { guests: this.guests })
    },

    onChildCountUpdate (count = 0) {
      this.$store.dispatch('invitation/update', { kidsCount: count })
    },

    compareTo (item, value) {
      return this.guests
        .some(guest => guest.rsvp[item.key] === value)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-rsvp {
    padding: 12rem 0rem;
    background-color: rgba($color-secondary, .15);
    border-top: .1rem solid $color-primary;
    border-bottom: .1rem solid $color-primary;

    &__title {
      font-size: 1.6rem;
      line-height: 2.6rem;
      letter-spacing: 5%;
      text-align: center;
      text-transform: uppercase;
    }

    &__block {
      width: 32rem;
      margin: 0 auto;
      margin-top: 4rem;

      &:not(:last-child) {
        margin-bottom: 10rem;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      &-label {
        font-size: 1.6rem;
      }

      &-btns {
        display: flex;
        align-items: center;

        .btn {
          font-family: "Prato", serif;
          min-width: 8rem;

          &:not(:last-child) {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
</style>
